var coll = document.getElementsByClassName("collapsible");
var i;
for (i = 0; i < coll.length; i++) {
  coll[i].addEventListener("click", function () {
    this.classList.toggle("active");
    var rect = this.getBoundingClientRect();
    var body = document.body;
    var html = document.documentElement;

    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    let vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0,
    );

    var content = this.nextElementSibling;
    if (content.style.maxHeight) {
      content.style.maxHeight = null;
      const scrolly = this.getAttribute("data-scrolly");
      // window.scroll({
      //   top: scrolly,
      //   behavior: "smooth",
      // });
    } else {
      console.log("expand");
      this.setAttribute("data-scrolly", window.scrollY);
      content.style.maxHeight = content.scrollHeight + "px";
      const y = content.getBoundingClientRect().top + window.scrollY;
      var my = (-vh + content.scrollHeight) / 2 + y;
      // window.scroll({
      //   top: my,
      //   behavior: "smooth",
      // });
    }
  });
}
